import Echo from 'laravel-echo'
window.io = require('socket.io-client');

export default {
    data() {
        return {
            events: {
                status:{},
                messages:[],
            },
            pusher: null,
            channel:null
        }
    },
    methods: {
        makeChannel(prefix){
            if (!prefix) prefix = 'Batch';
            if (!this.channel) this.channel = prefix+'.'+this.user.token+"."+this.model;
        },
        initPusher(callback) {
            this.pusher = new Echo({
                broadcaster: 'socket.io',
                host: this.$organization.host_url
            });
            if (!this.channel) this.makeChannel();
            this.pusher.channel(this.channel)
                .listen('System', (e) => {
                   if (callback) callback(e);
                   if (e.message.message) this.events.messages.push(e.message.message);
                   this.events.status = Object.assign({},this.events.status, e.message.status);
                });
            this.connectPusher();
        },
        resetEvents(){
            this.events =  {
                status:{},
                messages:[],
            };
        },
        disconnectPusher() {
            this.resetEvents();
            if (this.pusher) {
                this.pusher.disconnect();
            }
        },
        connectPusher() {
            if (this.pusher) this.pusher.connect();
            else {
                this.initPusher();
            }
        },
        addChannel(payload) {
            return Object.assign({channel:this.channel},payload);
        }
    },
    destroyed() {
        this.disconnectPusher();
        this.pusher && this.pusher.leaveChannel(this.channel);
    },
}
